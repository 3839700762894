<template>
  <div class="event-marketing-setting">
    <p class="card-title">行銷內容設定</p>
    <el-form
      ref="formRef"
      label-position="top"
      :model="formData"
      :rules="formRules"
    >
      <el-form-item label="領取次數規則" prop="sendLimit" required>
        <el-select
          v-model="formData.sendLimit"
          :disabled="isEdit || !!accumulation"
        >
          <el-option
            v-for="item in typeOfEventNumberConfig"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
        <p class="tips">{{ sendLimitWord }}</p>
      </el-form-item>

      <el-form-item label="選擇行銷項目" prop="eventAwardType" required>
        <el-select v-model="formData.eventAwardType" @change="resetCoupon">
          <el-option
            v-for="item in featureKeysByEventAwardConfig"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>

      <!-- 行銷項目：堂票 -->
      <el-form-item
        v-if="formData.eventAwardType === 'classTicket'"
        label="選擇行銷票券"
        prop="classTicketId"
        required
      >
        <EventClassTicketSelect :classTicketId.sync="formData.classTicketId" />
      </el-form-item>

      <!-- 行銷項目：票券 -->
      <el-form-item
        v-if="formData.eventAwardType === 'coupon'"
        label="選擇票券"
        prop="couponId"
        required
      >
        <EventCouponSelect :couponId.sync="formData.couponId" :couponType="couponType" />
      </el-form-item>

      <!-- 行銷項目： 堂票 票券 回饋金 點數 -->
      <el-form-item
        v-if="
          formData.eventAwardType && formData.eventAwardType !== 'lineMessage'
        "
        label="發送數量"
        prop="eventAwardCount"
        required
      >
        <el-input v-model="formData.eventAwardCount" />
      </el-form-item>

      <!-- 行銷項目：圖文訊息 -->
      <el-form-item
        v-if="formData.eventAwardType === 'lineMessage'"
        label="圖文訊息設置"
        prop="eventMessage"
        required
        class="message"
      >
        <EventMessageBlock :eventMessage.sync="formData.eventMessage" />
      </el-form-item>

      <!-- 發送時間 -->
      <el-form-item label="發送時間" prop="sendDateSetting" required>
        <el-radio-group
          v-model="formData.sendDateSetting"
          class="flex flex-col"
          style="gap: 20px"
          @change="resetTime"
        >
          <div>
            <el-radio label="immediately">
              <div class="inline-flex items-center" style="gap: 4px">
                <span>當下發送</span>
                <!-- <span v-show="sourceType === 'memberExternalTransactionCompleted'">(依據POS端同步銷售資料後時間發送：約凌晨6:00)</span> -->
                <el-tooltip
                  placement="right"
                  class="condition-help"
                >
                  <div slot="content" class="w-[200px]">
                    當日的第三方消費紀錄撈取資料時間為隔日凌晨，詳細時間會依據各分店所串接的POS系統：<br>
                    微碧POS - 約01:30AM<br>
                    瑞乘POS - 約04:00AM<br>
                  </div>
                  <span class="relative" style="top: 2px">
                    <MaterialIcon v-show="sourceType === 'memberExternalTransactionCompleted'" size="20">
                      help_ouline
                    </MaterialIcon>
                  </span>
                </el-tooltip>
              </div>
            </el-radio>
          </div>
          <div v-if="showSpecifySendDay">
            <el-radio label="specify">
              指定時間
              <div
                class="flex"
                style="margin-left: 25px; margin-top: 8px; gap: 20px"
              >
                <div class="flex items-center" style="gap: 20px">
                  <span>隔</span>
                  <el-form-item prop="part.specifySendDay">
                    <el-input
                      v-model="formData.part.specifySendDay"
                      :disabled="formData.sendDateSetting !== 'specify'"
                      style="width: 100px"
                      placeholder="請輸入"
                    />
                  </el-form-item>
                  <span>天後的</span>
                </div>

                <el-form-item :show-message="false" prop="specifySendTime">
                  <div class="flex items-center" style="gap: 20px">
                    <el-time-picker
                      v-model="formData.part.specifySendTime"
                      style="width: 135px"
                      :disabled="formData.sendDateSetting !== 'specify'"
                      disabled-seconds
                      format="HH:mm"
                      :picker-options="timeOptions"
                      placeholder="請選擇時間"
                    />
                    <span>發送</span>
                  </div>
                </el-form-item>
              </div>
            </el-radio>
          </div>
        </el-radio-group>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  reactive,
  onMounted,
  nextTick,
  computed,
  watch,
} from 'vue'
import { get, set, toNumber } from 'lodash'
import dayjs from 'dayjs'
import {
  noEmptyRules,
  isDigitRules,
  integerRules,
  minRules,
} from '@/validation'
import {
  eventNumberOfReceiptsConfig,
  eventAwardConfig,
  eventTipsConfig,
} from '@/config/marketing'
import EventMessageBlock from './EventMessageBlock.vue'
import EventCouponSelect from './EventCouponSelect.vue'
import EventClassTicketSelect from './EventClassTicketSelect.vue'
import { usePermissions } from '@/use/permissions'
import store from '@/store'

export default defineComponent({
  name: 'EventMarketingContent',
  components: {
    EventMessageBlock,
    EventCouponSelect,
    EventClassTicketSelect,
  },
  props: [
    'FormsContext',
    'eventData',
    'sourceType',
    'accumulation',
    'eventType',
  ],
  setup (props) {
    const { checkAction } = usePermissions()
    const formRef = ref(null)

    const formData = reactive({
      sendLimit: null,
      eventAwardType: null,
      sendDateSetting: null,
      eventAwardCount: null,
      couponId: null,
      classTicketId: null,
      part: {
        specifySendDay: null,
        specifySendTime: null,
      },
      eventMessage: [
        {
          type: 'text',
          content: '請輸入內容',
          action: undefined,
          actionContent: undefined,
        },
      ],
    })

    const couponType = computed(() => {
      const couponTypeArray = []
      if (get(formData, 'eventAwardType') === 'coupon') {
        if (checkAction('admin.coupon.page')) couponTypeArray.push('coupon')
        if (checkAction('admin.couponExchange.page')) couponTypeArray.push('exchange')
      }
      return couponTypeArray
    })

    const timeOptions = computed(() => {
      if (props.sourceType !== 'memberExternalTransactionCompleted') {
        return {
          selectableRange: '00:00:00 - 23:59:59',
        }
      }
      return {
        selectableRange: '00:00:00 - 23:59:59',
      }
      // if (toNumber(formData.part.specifySendDay) === 1) {
      //   return {
      //     selectableRange: '06:00:00 - 23:59:59',
      //   }
      // } else {
      //   return {
      //     selectableRange: '00:00:00 - 23:59:59',
      //   }
      // }
    })

    const formRules = computed(() => {
      const rules = {
        sendLimit: [noEmptyRules('請輸入領取次數規則')],
        eventAwardType: [noEmptyRules('請輸入行銷項目')],
        sendDateSetting: [noEmptyRules('請選擇發送時間')],
        StandardMessage: [noEmptyRules('請輸入圖文訊息')],
        couponId: [noEmptyRules('請選擇票券')],
        classTicketId: [noEmptyRules('請選擇堂票')],
        eventAwardCount: [
          noEmptyRules('請輸入數量'),
          isDigitRules(),
          integerRules(),
        ],
      }
      if (formData.sendDateSetting === 'specify') {
        rules['part.specifySendDay'] = [
          noEmptyRules('請輸入天數'),
          minRules(1, '指定時間最少須設定1天（隔日），不可輸入0天（當日）'),
        ]
      }
      return rules
    })

    const featureKeys = {
      classTicket: 'admin.classTicket.page',
      coupon: 'admin.coupon.page',
      point: 'admin.shopPoint.page',
      cashback: 'admin.shopCashback.page',
    }

    const featureKeysByEventAwardConfig = computed(() => {
      const marketingPermission = store.getters.computedShopMarketingPermission
      return eventAwardConfig.filter(({ value }) => {
        // 推薦禮移除圖文訊息
        if (props.sourceType === 'memberReferralRecordSuccessReferrer' ||
         props.sourceType === 'memberReferralRecordSuccessReferee'
        ) {
          return value !== 'lineMessage' && marketingPermission[value]
        }
        if (featureKeys[value]) {
          if (marketingPermission[value]) {
            return true
          }
          return checkAction(featureKeys[value]) && marketingPermission[value]
        }
        return true
      })
    })

    const isEdit = computed(() => {
      return !!get(props.eventData, 'id')
    })

    const typeOfEventNumberConfig = computed(() => {
      const config = ['firstOrderAmount', 'firstOrderNumber', 'registerAndLineAuth']
      if (props.sourceType === 'register') {
        return eventNumberOfReceiptsConfig.filter(
          ({ value }) => value !== 'repeat',
        )
      }

      // 過濾只顯示只領取1次
      if (config.includes(props.eventType)) {
        return eventNumberOfReceiptsConfig.filter(
          ({ value }) => value === 'once',
        )
      }
      return eventNumberOfReceiptsConfig
    })

    const sendLimitWord = computed(() => {
      if (props.accumulation) {
        return '規則說明：已套用上方事件範本的累積計算方式作為領取次數規則'
      }
      return props.sourceType && formData.sendLimit
        ? eventTipsConfig[props.sourceType][formData.sendLimit]
        : ''
    })

    const showSpecifySendDay = computed(() => {
      const config = ['level', 'birthday']
      return !config.includes(props.sourceType)
    })

    const compactData = computed(() => {
      const data = {
        sendLimit: get(formData, 'sendLimit'),
        eventAwardType: get(formData, 'eventAwardType'),
        eventAwardCount: get(formData, 'eventAwardCount'),
        sendDateSetting: get(formData, 'sendDateSetting'),
      }
      if (data.eventAwardType === 'lineMessage') {
        data.messages = get(formData, 'eventMessage')
      }
      if (data.eventAwardType === 'coupon') {
        data.relatedId = [get(formData, 'couponId')]
      }
      if (data.eventAwardType === 'classTicket') {
        data.relatedId = [get(formData, 'classTicketId')]
      }
      if (data.sendDateSetting === 'specify') {
        const hour = dayjs(formData.part.specifySendTime).format('HH')
        const minute = dayjs(formData.part.specifySendTime).format('mm')
        data.specifySendDay = get(formData.part, 'specifySendDay')
        data.specifySendTime = Number(hour * 60) + Number(minute)
      }
      return { ...data }
    })

    const syncData = () => {
      const data = props.eventData
      const { award } = data.config
      set(formData, 'sendLimit', award.sendLimit)
      set(formData, 'eventAwardType', award.type)
      set(formData, 'sendDateSetting', award.sendDateSetting)
      set(formData, 'eventAwardCount', award.amount)
      set(formData.part, 'specifySendDay', award.specifySendDay)
      set(
        formData.part,
        'specifySendTime',
        dayjs().startOf('day').add(award.specifySendTime, 'minutes'),
      )
      if (award.type === 'coupon') {
        set(formData, 'couponId', award.relatedId[0])
      }
      if (award.type === 'classTicket') {
        set(formData, 'classTicketId', award.relatedId[0])
      }
      if (award.type === 'lineMessage') {
        set(
          formData,
          'eventMessage',
          award.messages.map((item) => {
            return {
              type: 'text',
              content: '請輸入內容',
              action: undefined,
              actionContent: undefined,
              ...item,
            }
          }),
        )
      }
    }

    onMounted(async () => {
      await nextTick()

      if (get(props.eventData, 'id')) syncData()
      props.FormsContext.setFormRef('content', formRef.value)
    })

    watch(formData, () => {
      props.FormsContext.setFormData('content', { ...compactData.value })
    })

    const resetCoupon = () => {
      formData.couponId = null
      formData.classTicketId = null
    }

    const trans = {
      rate: 'repeat',
      once: 'once',
    }

    watch(
      () => props.accumulation,
      (newValue) => {
        formData.sendLimit = trans[newValue]
      },
    )
    watch(
      () => props.eventType,
      (newValue) => {
        formData.sendLimit = null
      },
    )
    const resetTime = () => {
      formData.part = {
        specifySendDay: null,
        specifySendTime: null,
      }
    }

    return {
      formRef,
      formData,
      formRules,
      eventNumberOfReceiptsConfig,
      featureKeysByEventAwardConfig,
      resetCoupon,
      sendLimitWord,
      isEdit,
      resetTime,
      showSpecifySendDay,
      typeOfEventNumberConfig,
      timeOptions,
      couponType,
    }
  },
})
</script>

<style lang="postcss" scoped>
.message ::v-deep .el-input {
  @apply w-full;
}
.tips {
  @apply text-[#636363];
}
</style>
