import { ref } from 'vue'
import { useFetch } from '@/use/fetch'
import { GetMemberLevelConfig } from '@/api/memberLevel'
import { useShop } from '@/use/shop'

export const useMemberLevel = () => {
  const { simpleFetch } = useFetch()
  const { shopId } = useShop()

  const memberLevelConfig = ref([])

  const getMemberLevelConfig = async () => {
    await simpleFetch(GetMemberLevelConfig, { shopId: shopId.value }, res => {
      memberLevelConfig.value = res
    })
  }

  return { getMemberLevelConfig, memberLevelConfig }
}
