<template>
  <div class="event-marketing-setting">
    <p class="card-title">事件設定</p>
    <el-form
      ref="formRef"
      label-position="top"
      :model="formData"
      :rules="formRules"
    >
      <el-form-item label="事件類型" prop="source" required>
        <el-select
          v-model="formData.source"
          :disabled="isEdit"
          @change="onSourceChange"
        >
          <el-option
            v-for="item in featureKeysByEventSourceConfig"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="事件範本" prop="type" required>
        <el-select
          v-model="formData.type"
          no-data-text="請先選擇事件類型"
          :disabled="isEdit"
          @change="
            (e) => {
              typeChangeHandler()
              changeType(e)
            }
          "
        >
          <el-option
            v-for="item in eventType"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
        <p
          v-if="
            formData.source === 'birthday' &&
              formData.type === 'birthCurrentMonth'
          "
          class="tips"
        >
          於活動期間系統會在每月的 1 號凌晨 0:00 計算當月壽星資格。
        </p>
      </el-form-item>

      <p
        v-if="formData.source === 'memberExternalTransactionCompleted'"
        class="tips text-sub flex"
        style="margin-bottom: 20px; gap: 8px"
      >
        <span>規則說明：POS消費金額只認列特定支付方式，其他支付方式不認列</span>
        <router-link :to="{name: 'PosPaymentRecognize'}" target="_blank">
          <p class="underline">詳細說明</p>
        </router-link>
      </p>

      <el-form-item
        v-if="showProductRow()"
        label="選擇指定商品"
        prop="spec.ids"
        required
      >
        <EventMemberStoreProduct
          v-if="showProductRow('memberStoreOrderCompleted')"
          :productId.sync="formData.spec.ids"
          :isDisabled="isEdit"
        />
        <EventSalesProduct
          v-if="showProductRow('saleRecordCompleted')"
          :productId.sync="formData.spec.ids"
          :isDisabled="isEdit"
        />
        <EventAppointmentProduct
          v-if="showProductRow('appointmentOrderCompleted')"
          :productId.sync="formData.spec.ids"
          :isDisabled="isEdit"
        />
        <EventEcommerceProduct
          v-if="showProductRow('ecOrderCompleted')"
          :productId.sync="formData.spec.ids"
          :isDisabled="isEdit"
        />
      </el-form-item>

      <MemberInviteAmountSettingBlock
        :form.sync="formData"
        :isEdit="isEdit"
        :setAccumulation="setAccumulation"
        :amountTypeChangeHandler="amountTypeChangeHandler"
      />

      <el-form-item v-if="showAccumulationConfig" label="累積計算方式" required>
        <el-select
          v-model="formData.sumUp.type"
          :disabled="isEdit"
          @change="setAccumulation"
        >
          <el-option
            v-for="item in eventSumUpConfigConfig"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
        <p v-if="formData.sumUp.type === 'rate'" class="tips">
          規則說明：活動期間的所有消費累積會每次計算，如「累積」達到條件的倍數，則會贈送該倍數的獎勵
          <el-tooltip placement="right">
            <div slot="content">
              舉例每累積3000元送 1張，A第一次消費3000元<br>
              時贈送1張 ; 當A第二次消費6000元時，由於滿足<br>
              3000元的倍數，則會再贈送2張
            </div>
            <span class="underline">看範例</span>
          </el-tooltip>
        </p>
        <p v-if="formData.sumUp.type === 'once'" class="tips">
          規則說明：同一位會員於活動期間只能領取 1 次
        </p>
      </el-form-item>

      <el-form-item v-if="showAmountConfig" label="消費滿額設置" required>
        <el-radio-group
          v-model="formData.amountConfig.amountType"
          class="flex flex-col"
          style="gap: 20px; padding-top: 8px"
          :disabled="isEdit"
          @change="amountTypeChangeHandler"
        >
          <div>
            <el-radio label="threshold">
              {{ amountConfig.configSpec.title }}
              <div
                class="flex items-center gap-[20px]"
                style="margin-left: 25px; margin-top: 8px; gap: 20px"
              >
                單筆消費滿
                <el-form-item prop="amountMin">
                  <el-input
                    v-model="formData.amountConfig.amountMin"
                    :disabled="
                      isEdit || formData.amountConfig.amountType !== 'threshold'
                    "
                    style="width: 100px"
                    placeholder="請輸入"
                  />
                </el-form-item>
                {{ amountConfig.configSpec.unit }}
              </div>
            </el-radio>
          </div>
          <div v-if="amountConfig.configRange">
            <el-radio label="range">
              {{ amountConfig.configRange.title }}
              <div
                class="flex items-center gap-[20px]"
                style="margin-left: 25px; margin-top: 8px; gap: 20px"
              >
                單筆消費滿
                <el-form-item prop="amountRangeMin">
                  <el-input
                    v-model="formData.amountConfig.amountRangeMin"
                    :disabled="
                      isEdit || formData.amountConfig.amountType !== 'range'
                    "
                    style="width: 100px"
                    placeholder="請輸入"
                  />
                </el-form-item>
                ～
                <el-form-item prop="amountMax">
                  <el-input
                    v-model="formData.amountConfig.amountMax"
                    :disabled="
                      isEdit || formData.amountConfig.amountType !== 'range'
                    "
                    style="width: 100px"
                    placeholder="請輸入"
                  />
                </el-form-item>
                {{ amountConfig.configRange.unit }}
              </div>
            </el-radio>
          </div>
        </el-radio-group>
      </el-form-item>

      <el-form-item
        v-if="showAccumulationConfig"
        :label="amountConfig.configSpec.title"
        required
      >
        <div
          style="margin-left: 25px"
          class="text-[16px] flex items-center gap-[20px]"
        >
          累積訂單滿
          <el-input
            v-model="formData.amountConfig.amountMin"
            style="width: 100px"
            placeholder="請輸入"
            :disabled="isEdit"
          />
          {{ amountConfig.configSpec.unit }}
        </div>
      </el-form-item>

      <el-form-item v-if="showAccumulationConfig" prop="sumUpBackToDays">
        <div
          class="flex items-center"
          style="gap: 8px; margin-bottom: 8px text-[#333]"
        >
          <p class="font-medium text-[#333] text-[16px]">
            <span class="text-danger">* </span>回溯歷史區間
          </p>
          <el-tooltip placement="right">
            <div slot="content">累積訂單可追加活動期間「開始日期」往前回推的天數進行計算</div>
            <span class="material-icons">help_outline</span>
          </el-tooltip>
        </div>
        <el-radio-group
          v-model="formData.sumUp.backToDays"
          class="flex flex-col"
          style="gap: 20px; padding-top: 8px"
          :disabled="isEdit"
        >
          <el-radio
            v-for="item in eventHistoryConfig"
            :key="item.value"
            :label="item.value"
          >
            {{ item.label }}
          </el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  reactive,
  onMounted,
  nextTick,
  computed,
  watch,
} from 'vue'
import { noEmptyRules } from '@/validation'
import {
  eventSourceConfig,
  eventSumUpConfigConfig,
  eventHistoryConfig,
  eventTypeConsumption,
} from '@/config/marketing'
import { get, set, isArray } from 'lodash'
import { usePermissions } from '@/use/permissions'
import EventMemberStoreProduct from './EventMemberStoreProduct.vue'
import EventSalesProduct from './EventSalesProduct.vue'
import EventAppointmentProduct from './EventAppointmentProduct.vue'
import EventEcommerceProduct from './EventEcommerceProduct.vue'
import MemberInviteAmountSettingBlock from './MemberInviteAmountSettingBlock.vue'

export default defineComponent({
  name: 'EventMarketingSetting',
  components: {
    EventMemberStoreProduct,
    EventSalesProduct,
    EventAppointmentProduct,
    EventEcommerceProduct,
    MemberInviteAmountSettingBlock,
  },
  props: [
    'FormsContext',
    'eventData',
    'setSourceType',
    'setAccumulation',
    'setEventType',
  ],
  setup (props) {
    const formRef = ref(null)
    const formData = reactive({
      source: null,
      type: null,
      amountConfig: {
        amountType: 'threshold',
        amountMin: null,
        amountRangeMin: null,
        amountMax: null,
      },
      sumUp: {
        type: null,
        backToDays: 0,
      },
      spec: {
        type: null,
        ids: null,
      },
    })
    const { checkAction, checkActionList } = usePermissions()

    const textSettingConfig = [
      'firstOrderAmount',
      'firstOrderNumber',
      'orderAmount',
      'orderNumber',
      'orderSumAmount',
      'orderSumNumber',
      'orderSpecAmount',
      'orderSpecNumber',
      'orderSumSpecAmount',
      'orderSumSpecNumber',
    ]

    // 消費滿額設置範本
    const amountSettingConfig = [
      'firstOrderAmount',
      'firstOrderNumber',
      'orderAmount',
      'orderNumber',
      'orderSpecAmount',
      'orderSpecNumber',
    ]

    // 需選擇指定商品範本
    const productConfig = [
      'orderSpecAmount',
      'orderSpecNumber',
      'orderSumSpecAmount',
      'orderSumSpecNumber',
    ]

    // 累積計算方式範本
    const accumulationConfig = [
      'orderSumAmount',
      'orderSumNumber',
      'orderSumSpecAmount',
      'orderSumSpecNumber',
    ]

    const formRules = {
      source: [noEmptyRules('請輸入事件類型')],
      type: [noEmptyRules('請輸入事件範本')],
    }

    const isEdit = computed(() => {
      return !!get(props.eventData, 'id')
    })

    // 顯示選擇指定商品
    const showProductRow = (source) => {
      if (!source) {
        return productConfig.includes(formData.type)
      }
      return formData.source === source && productConfig.includes(formData.type)
    }

    // 顯示消費滿額設置
    const showAmountConfig = computed(() => {
      return amountSettingConfig.includes(formData.type)
    })

    // 顯示累積計算方式
    const showAccumulationConfig = computed(() => {
      return accumulationConfig.includes(formData.type)
    })

    const eventType = computed(() => {
      const current = featureKeysByEventSourceConfig.value.find((item) => {
        return item.value === formData.source
      })

      return current?.type || []
    })

    //  mapping featureKeys
    const featureKeys = {
      saleRecordCompleted: 'admin.salesRecord.page',
      ecOrderCompleted: 'admin.ecommercePage.page',
      memberStoreOrderCompleted: 'admin.memberStoreOrder.page',
      appointmentOrderCompleted: 'admin.appointmentService.page',
      memberExternalTransactionCompleted: ['admin.memberExternalTransaction.page', 'admin.shopCustomModule.posPalMemberWallet'],
    }

    const featureKeysByEventSourceConfig = computed(() => {
      return eventSourceConfig.filter(({ value }) => {
        const featureKey = get(featureKeys, value)
        if (!featureKey) return true
        if (isArray(featureKey)) return checkActionList(featureKey, 'intersection')
        return checkAction(featureKey)
      })
    })

    const onSourceChange = (source) => {
      props.setSourceType(source)
      formData.type = null
    }

    const changeType = (type) => {
      props.setEventType(type)
    }

    const compactData = computed(() => {
      const data = {
        source: get(formData, 'source'),
        type: get(formData, 'type'),
      }

      // 推薦禮
      if (['memberReferralRecordSuccessReferrer', 'memberReferralRecordSuccessReferee'].includes(formData.source)) {
        const { amountType, amountMin, amountRangeMin, amountMax } =
          formData.amountConfig

        data.sumUp = get(formData, 'sumUp')
        data.amountConfig = {
          amountType,
          amountMax: amountMax || 0,
          amountMin: amountMin || amountRangeMin || 0,
        }
      }

      if (amountSettingConfig.includes(formData.type)) {
        const { amountType, amountMin, amountRangeMin, amountMax } =
          formData.amountConfig
        data.amountConfig = {
          amountType,
          amountMax: amountMax || 0,
          amountMin: amountMin || amountRangeMin || 0,
        }
      }
      if (accumulationConfig.includes(formData.type)) {
        const { amountMin } = formData.amountConfig
        data.sumUp = get(formData, 'sumUp')
        data.amountConfig = {
          amountType: 'threshold',
          amountMin,
          amountMax: 0,
        }
      }
      if (productConfig.includes(formData.type)) {
        data.spec = {
          type: eventSourceConfig.find(({ value }) => value === formData.source)
            ?.eventSpecsType,
          ids: formData.spec.ids ? [formData.spec.ids] : [],
        }
      }
      return { ...data }
    })

    const syncData = () => {
      const data = props.eventData
      const { amountConfig, sumUp, spec } = data.config || {}
      const { amountType, amountMin, amountMax } = amountConfig || {}
      set(formData, 'source', data.source)
      set(formData, 'type', data.type)
      set(formData.amountConfig, 'amountType', amountType)
      set(formData.amountConfig, 'amountMax', amountMax)
      if (amountType === 'range') {
        set(formData.amountConfig, 'amountRangeMin', amountMin)
      } else {
        set(formData.amountConfig, 'amountMin', amountMin)
      }
      set(formData, 'sumUp', sumUp)
      props.setSourceType(data.source)
      if (sumUp) {
        props.setAccumulation(sumUp.type)
      }
      if (spec) {
        set(formData.spec, 'ids', spec.ids[0])
        set(formData.spec, 'type', spec.type)
      }
    }

    onMounted(async () => {
      await nextTick()

      if (get(props.eventData, 'id')) syncData()
      props.FormsContext.setFormRef('setting', formRef.value)
    })

    watch(formData, () => {
      props.FormsContext.setFormData('setting', { ...compactData.value })
    })

    const amountConfig = computed(() => {
      if (textSettingConfig.includes(formData.type)) {
        const { configSpec, configRange } = eventTypeConsumption.find(
          ({ value }) => value === formData.type,
        )
        return { configSpec, configRange }
      }
      return null
    })

    const amountTypeChangeHandler = () => {
      formData.amountConfig.amountMin = null
      formData.amountConfig.amountRangeMin = null
      formData.amountConfig.amountMax = null
      formData.spec.ids = null
    }

    const typeChangeHandler = () => {
      formData.amountConfig.amountType = 'threshold'
      formData.spec.ids = null
      amountTypeChangeHandler()
      if (!showAccumulationConfig.value) {
        props.setAccumulation(null)
        formData.sumUp.type = null
      }
    }

    return {
      formRef,
      formData,
      formRules,
      featureKeysByEventSourceConfig,
      eventSumUpConfigConfig,
      eventHistoryConfig,
      eventTypeConsumption,
      eventType,
      onSourceChange,
      showAmountConfig,
      amountConfig,
      amountTypeChangeHandler,
      typeChangeHandler,
      showAccumulationConfig,
      isEdit,
      changeType,
      showProductRow,
    }
  },
})
</script>

<style lang="postcss" scoped>
.tips {
  @apply text-[#636363];
}
::v-deep .el-radio {
  @apply text-[#333];
}
</style>
