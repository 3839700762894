import { admin2 } from '@/api/instance'

// 事件行銷列表
export const GetEventMarketingList = async ({
  shopId,
  start,
  limit,
  name,
  status,
}) => {
  return await admin2({
    method: 'get',
    url: `/${shopId}/eventTemplateConfig`,
    params: {
      start,
      limit,
      name,
      status,
    },
  })
}

// 事件行銷數量
export const GetEventMarketingCount = async ({ shopId, name, status }) => {
  return await admin2({
    method: 'get',
    url: `/${shopId}/eventTemplateConfig/count`,
    params: {
      name,
      status,
    },
  })
}

// 單一事件行銷
export const FindEventMarketing = async ({ shopId, id }) => {
  return await admin2({
    method: 'get',
    url: `/${shopId}/eventTemplateConfig/${id}`,
  })
}

// 新增事件行銷
export const CreateEventMarketing = async ({ shopId, postBody }) => {
  return await admin2({
    method: 'post',
    url: `/${shopId}/eventTemplateConfig`,
    data: {
      ...postBody,
    },
  })
}

// 更新事件行銷
export const UpdateEventMarketing = async ({ shopId, id, postBody }) => {
  return await admin2({
    method: 'put',
    url: `/${shopId}/eventTemplateConfig/${id}`,
    data: {
      ...postBody,
    },
  })
}

// 刪除事件行銷
export const DeleteEventMarketing = async ({ shopId, id }) => {
  return await admin2({
    method: 'delete',
    url: `/${shopId}/eventTemplateConfig/${id}`,
  })
}
